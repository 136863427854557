import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

export const useLogout = () => {
	const navigate = useNavigate();
	return useCallback(() => {
		supabase.auth.signOut();
		navigate('/');
	}, [navigate]);
};
