import { QueryClientProvider } from '@tanstack/react-query';
import Modal from 'react-modal';

import { Flow } from '../Flow/Flow';
import { queryClient } from '../Flow/api';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { toastError } from '../utils';

Modal.setAppElement('#root');

export function App() {
	const logout = useLogout();
	const [storyTitle, setStoryTitle] = useState('');

	const { storyId } = useParams();

	useEffect(() => {
		(async () => {
			// Query the story title from supabase
			const { data, error } = await supabase
				.from('stories')
				.select('title')
				.eq('id', storyId)
				.limit(1)
				.maybeSingle();

			if (error) {
				toastError(error.message);
				console.log(error);
				return;
			}

			if (data) {
				setStoryTitle(data.title);
			}
		})();
	}, [storyId]);

	return (
		<QueryClientProvider client={queryClient}>
			<div className="App">
				<header className="App-header">
					<span>
						<Link to="/app">
							<img src="/logo-text.svg" height="40px" alt="logo" />
						</Link>
					</span>
					|
					<span>
						<a href="https://forms.gle/5z9WnJL691d5oQ73A" style={{ textDecorationLine: 'none' }}>
							Waitlist
						</a>
					</span>
					|
					<span>
						<a href="https://discord.gg/qMrcprgejB" style={{ textDecorationLine: 'none' }}>
							Discord
						</a>
					</span>
					|
					<span>
						<a href="#" onClick={logout} style={{ textDecorationLine: 'none' }}>
							Logout
						</a>
					</span>
					|<span>Current title: {storyTitle}</span>
				</header>
				{storyId && <Flow storyId={storyId} />}
				{/* <Modal
						isOpen={hash === '#instructions'}
						shouldCloseOnEsc={true}
						shouldFocusAfterRender={true}
						onRequestClose={closeModal}
					>
						<div style={{ float: 'right', cursor: 'pointer' }} onClick={closeModal}>
							<AiOutlineClose color="red" />
						</div>
						<InstructionsContent onClose={closeModal} />
					</Modal> */}
			</div>
		</QueryClientProvider>
	);
}
