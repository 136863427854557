import { memo, useCallback, useState } from "react";
import { Handle, Position, NodeProps, NodeToolbar } from "reactflow";
import { shallow } from "zustand/shallow";
import { RFState, useStore } from "../store";
import { toastNotify } from "../../utils";
import { NodeData } from "../nodes";
import { generateStoryBottom } from "../api";
import TextareaAutosize from "react-textarea-autosize";

import "@reactflow/node-resizer/dist/style.css";
import { Loading } from "../Loading";
const selector = (state: RFState) => ({
  updateNodeData: state.updateNodeData,
  getNodeById: state.getNodeById,
  getNodesByIds: state.getNodesByIds,
  getChildren: state.getChildren,
  getSummary: state.getSummary,
  getMidNodeChoices: state.getMidNodeChoices,
  getThisChapterSummary: state.getThisChapterSummary,
  getParent: state.getParent,
  convertNodeType: state.convertNodeType,
});

export const BottomNode = memo(
  ({ data, selected, id }: NodeProps<NodeData>) => {
    const {
      updateNodeData,
      getThisChapterSummary,
      getParent,
      getNodeById,
      convertNodeType,
    } = useStore(selector, shallow);

    const [textLoading, setTextLoading] = useState(false);

    const onDataChange = useCallback(
      (delta: Partial<NodeData>) => {
        // console.log(evt.target.value);
        updateNodeData(id, delta);
      },
      [id, updateNodeData]
    );

    const onGenerateWriting = useCallback(async () => {
      setTextLoading(true);

      const chapterEvents = data.events;
      const chapterTheme = data.theme;
      const chapterPreviousSummary =
        (await getThisChapterSummary(id)) || "nothing yet in this chapter";

      const parent = getParent(id);
      let parentEvents = "";
      let parentTheme = "";
      if (parent) {
        const parentNode = getNodeById(parent);
        parentEvents = parentNode?.data?.events || "";
        parentTheme = parentNode?.data?.theme || "";
      }

      const summary = await generateStoryBottom({
        chapterEvents,
        chapterTheme,
        chapterPreviousSummary,
        parentEvents,
        globalTheme: parentTheme,
      });

      console.log("summary", summary);
      const generatedText = summary.generated[0].trim();
      console.log("generatedText", generatedText);
      if (generatedText) {
        toastNotify(`Writing generated.`);
        onDataChange({ summary: generatedText });
      } else {
        toastNotify(`Writing generation failed.`);
      }
      setTextLoading(false);
    }, [
      data.events,
      data.theme,
      getNodeById,
      getParent,
      getThisChapterSummary,
      id,
      onDataChange,
    ]);

    const convertToMidNode = useCallback(() => {
      convertNodeType(id, "mid");
    }, [id, convertNodeType]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        className={selected ? "selected" : ""}
      >
        <Handle type="target" position={Position.Top} />
        {/* <NodeResizer isVisible={selected} /> */}
        <NodeToolbar isVisible={selected} position={Position.Bottom}>
          <button className="btn-green" onClick={onGenerateWriting}>
            Generate Text
          </button>
          <button className="btn-green" onClick={convertToMidNode}>
            Conver to Mid Node
          </button>
        </NodeToolbar>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Loading depArray={[textLoading]}>
            <div>
              <div>
                Label:{" "}
                <strong>
                  <input
                    type="text"
                    name={`label-${id}`}
                    value={data.label}
                    onChange={(e) => onDataChange({ label: e.target.value })}
                  />
                </strong>
              </div>

              <div>
                <label htmlFor={`theme-${id}`}>Theme:</label>
                <br />
                <TextareaAutosize
                  value={data.theme}
                  id={`theme-${id}`}
                  name={`theme-${id}`}
                  onChange={(e) => onDataChange({ theme: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`events-${id}`}>Events:</label>
                <br />
                <TextareaAutosize
                  value={data.events}
                  id={`events-${id}`}
                  name={`events-${id}`}
                  onChange={(e) => onDataChange({ events: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`summary-${id}`}>Text</label>
                <br />
                <TextareaAutosize
                  value={data.summary}
                  id={`summary-${id}`}
                  name={`summary-${id}`}
                  onChange={(e) => onDataChange({ summary: e.target.value })}
                  className="nodrag"
                />
              </div>
            </div>
          </Loading>
        </div>
      </div>
    );
  }
);
