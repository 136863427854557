// Sample response:
// {
// 	"generated": [
// 		"1. EVENT: An alien named 3M crash lands in France and discovers cheese. | THEME: discovery,2. EVENT: 3M tries to understand human culture and why they have cheese. | THEME: confusion, misunderstanding\n3. EVENT: 3M accidentally ruins a cheese shop with his experiments | THEME: destruction, conflict\n4. EVENT: 3M learns about some important cheeses and gains respect for the French. | THEME: success, gratification\n5. EVENT: 3M returns home and tells others about his adventures, French cheese. | THEME: sharing, spreading, closure",
// 		"1. EVENT: 3M, an alien, crashlands in France and discovers cheese. | THEME: discovery, wonder\n2. EVENT: 3M tries to blend in with the French people and learn about their culture. | THEME: assimilation, difference\n3. EVENT: 3M becomes a successful comedian in France and learns to love the people there. | THEME: love, acceptance, friendship\n4. EVENT: 3M decides to stay in France and make it his home. | THEME: commitment, belonging\n5. EVENT: The French people welcome 3M with open arms and celebrate his decision to stay. | THEME: inclusion, cultural exchange",
// 		"1. EVENT: 3M crash lands in France and discovers cheese. | THEME: discovery, wonder, exploration\n2. EVENT: 3M tries to communicate with the French, with hilarious results. | THEME: comedy, misunderstanding\n3. EVENT: 3M travels around France, exploring and trying new things. | THEME: joy, enlightenment\n4. EVENT: 3M returns home, but is still hungry for cheese. | THEME: sadness, desire\n5. EVENT: 3M finally gets to eat cheese again, and is happy. | THEME: contentment, fulfillment"
// 	]
// }

import { toastNotify } from '../utils';
import { CustomGenerateResponse } from './api';

const DESIRED_NUMBER_OF_SECTIONS = 5;

export interface EventThemePair {
	event: string;
	theme: string;
}

export const parseEventThemeResponse = (response: CustomGenerateResponse): EventThemePair[][] => {
	if (!response.generated) {
		toastNotify('Error: No generated text was returned from the API.');
		return [];
	}

	const parsed = response.generated
		.map(generated => {
			console.log('generated', generated);
			const split = generated.text.trim().split('\n');

			const sections = split.map(section => {
				const [event, theme] = section.split('|');

				if (event && theme) {
					// Remove the numbers and "EVENT: " from the beginning of the event
					const eventWithoutNumber = event
						.trim()
						.replace(/^\d+\. EVENT: /, '')
						.trim();
					// Remove the "THEME: " from the beginning of the theme
					const themeWithoutLabel = theme
						.trim()
						.replace(/^THEME: /, '')
						.trim();
					return { event: eventWithoutNumber, theme: themeWithoutLabel };
				}
				return { event, theme };
			});

			return sections;
		})
		.filter(sections => sections.length === DESIRED_NUMBER_OF_SECTIONS);
	return parsed;
};
