import { Link } from 'react-router-dom';
import { PublicFooter } from '../components/PublicFooter';
import { PublicHeader } from '../components/PublicHeader';
import './Home.scss';

export const Home = () => {
	return (
		<div className="marketing">
			<PublicHeader />
			<main role="main">
				<section className="ui-section-hero">
					<div className="ui-layout-container">
						{/* <!-- COPYWRITING --> */}
						<div className="ui-layout-column-6 ui-layout-column-center">
							<h1>Write better. Write faster.</h1>
							<p className="ui-text-intro">
								With Jotte, we're making token limits a thing of the past. Write to your limits,
								with the help of AI.
							</p>
							{/* <!-- CTA --> */}
							<div className="ui-component-cta ui-layout-flex">
								<a
									href="#"
									role="link"
									aria-label="#"
									className="ui-component-button ui-component-button-normal ui-component-button-primary"
								>
									Join our waitlist 🎉
								</a>
								<p className="ui-text-note">
									<small>More updates soon.</small>
								</p>
							</div>
						</div>
						{/* <!-- IMAGE --> */}
						<img
							src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad.png"
							loading="lazy"
							alt="#"
							className="ui-section-hero--image"
						/>
					</div>
				</section>
				<section className="ui-section-customer">
					<div className="ui-layout-container">
						<div className="ui-section-customer__layout ui-layout-flex">
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/logos/facebook.svg"
								alt="#"
								className="ui-section-customer--logo"
							/>
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/logos/pinterest.svg"
								alt="#"
								className="ui-section-customer--logo"
							/>
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/logos/stripe.svg"
								alt="#"
								className="ui-section-customer--logo ui-section-customer--logo-str"
							/>
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/logos/dribbble.svg"
								alt="#"
								className="ui-section-customer--logo"
							/>
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/logos/behance.svg"
								alt="#"
								className="ui-section-customer--logo ui-section-customer--logo-bhn"
							/>
						</div>
					</div>
				</section>
				<section className="ui-section-feature">
					<div className="ui-layout-container">
						<div className="ui-section-feature__layout ui-layout-grid ui-layout-grid-2">
							{/* <!-- IMAGE --> */}
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad.png"
								loading="lazy"
								alt="#"
								className="ui-image-half-left"
							/>
							{/* <!-- FEATURE --> */}
							<div>
								<h2>Ultra Longform Writing</h2>
								<p className="ui-text-intro">
									Current models by big players like OpenAI have a memory limit of ~3000 words.
								</p>
								<ul className="ui-component-list ui-component-list-feature ui-layout-grid">
									<li className="ui-component-list--item ui-component-list--item-check">
										Unlimited memory.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										O(log(n)) scaling for summaries
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										Lifetime updates.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										24/7 technical support.
									</li>
								</ul>
							</div>
						</div>
						<div className="ui-section-feature__layout ui-layout-grid ui-layout-grid-2">
							{/* <!-- FEATURE --> */}
							<div>
								<h2>Nice Features</h2>
								<p className="ui-text-intro">
									Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Enim ad minim veniam, quis nostrud
									exercitation ullamco nisi equi.
								</p>
								<ul className="ui-component-list ui-component-list-feature ui-layout-grid">
									<li className="ui-component-list--item ui-component-list--item-check">
										Unlimited domain names.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										150&plus; components.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										Lifetime updates.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										24/7 technical support.
									</li>
								</ul>
							</div>
							{/* <!-- IMAGE --> */}
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad.png"
								loading="lazy"
								alt="#"
								className="ui-image-half-right"
							/>
						</div>
						<div className="ui-section-feature__layout ui-layout-grid ui-layout-grid-2">
							{/* <!-- IMAGE --> */}
							<img
								src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad.png"
								loading="lazy"
								alt="#"
								className="ui-image-half-left"
							/>
							{/* <!-- FEATURE --> */}
							<div>
								<h2>Ultra long-form writing</h2>
								<p className="ui-text-intro">
									Current solutions by big players like OpenAI have a memory limit of ~3000 words..
								</p>
								<ul className="ui-component-list ui-component-list-feature ui-layout-grid">
									<li className="ui-component-list--item ui-component-list--item-check">
										Unlimited domain names.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										150&plus; components.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										Lifetime updates.
									</li>
									<li className="ui-component-list--item ui-component-list--item-check">
										24/7 technical support.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
				<section className="ui-section-testimonial">
					<div className="ui-layout-container">
						<div className="ui-layout-column-6 ui-layout-column-center">
							<h2>Wall of Love</h2>
							<p className="ui-text-intro">Lorem ipsum dolor sit amet.</p>
						</div>
						<div className="ui-section-testimonial__layout ui-layout-grid ui-layout-grid-3">
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
							<div className="ui-layout-column-4">
								<img
									src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg"
									alt="#"
									className="ui-section-testimonial--avatar"
								/>
								<p className="ui-section-testimonial--quote ui-text-intro">
									&ldquo;Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
									incididunt labore dolore magna.&rdquo;
								</p>
								<p className="ui-section-testimonial--author">
									<strong>Jane Doe</strong>
									<br />
									<small className="ui-text-note">CEO of Company</small>
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="ui-section-close">
					<div className="ui-layout-container">
						<div className="ui-layout-column-6 ui-layout-column-center">
							<h2>Improve your work.</h2>
							<p className="ui-text-intro">Lorem ipsum dolor sit amet consectetur.</p>
							{/* <!-- CTA --> */}
							<div className="ui-component-cta ui-layout-flex">
								<a
									href="#"
									role="link"
									aria-label="#"
									className="ui-component-button ui-component-button-normal ui-component-button-primary"
								>
									Add to Chrome &mdash; It&rsquo;s Free
								</a>
								<p className="ui-text-note">
									<small>Firefox and Safari soon.</small>
								</p>
							</div>
						</div>
						{/* <!-- IMAGE --> */}
						<img
							src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad-t.png"
							loading="lazy"
							alt="#"
							className="ui-section-hero--image"
						/>
					</div>
				</section>
			</main>
			<PublicFooter />
		</div>
	);
};
