// @ts-nocheck @typescript-eslint/no-unused-expressions

//  ========================
//  TYPEDEFS STOLEN BY COPYING FROM BACKEND
//  ========================
import { useQuery, useMutation, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { hc, InferResponseType, InferRequestType } from 'hono/client';
import { AppType } from '../../functions/api/[[route]]';
import { supabase_jwt, toastError } from '../utils';
export const queryClient = new QueryClient();

const API_URL =
	process.env.REACT_APP_LOCAL_BACKEND === 'true' ? 'http://localhost:8788/api' : '/api';

console.log('API_URL', API_URL);
console.log('process.env.LOCAL_BACKEND', process.env.REACT_APP_LOCAL_BACKEND);

const client = hc<AppType>(API_URL);

export interface BruhMoment {
	id: string;
	text: string;
}

export interface CustomGenerateResponse {
	generated: BruhMoment[];
}

export interface GenerateStoryOutlineArgs {
	theme: string;
	events: string;
}

export interface GenerateStoryMiddleArgs {
	globalTheme: string; // Theme of the whole story
	// Don't need global events, because we can just use the summary, and it makes AI anticipate things too much

	parentTheme: string; // Theme of the parent chapter
	parentEvents: string; // Events that will happen in the parent chapter
	chapterPreviousSummary: string; // Summary of previous events that happened in this chapter

	chapterEvents: string; // Events that will happen in this chapter
	chapterTheme: string; // Theme of this chapter
}

export interface GenerateStoryBottomArgs {
	globalTheme: string; // Theme of the whole story
	// Don't need global events, because we can just use the summary, and it makes AI anticipate things too much

	// parentTheme: string; // Theme of the parent chapter
	parentEvents: string; // Events that will happen in the parent chapter
	chapterPreviousSummary: string; // Summary of previous events that happened in this chapter

	chapterEvents: string; // Events that will happen in this chapter
	chapterTheme: string; // Theme of this chapter
}

export interface GenerateSummaryArgs {
	text: string;
}

export const generateStoryOutline = async (
	args: GenerateStoryOutlineArgs
): Promise<CustomGenerateResponse> => {
	const res = await client.gen.outline.$post(
		{ json: { password: 'roodab', ...args } },
		{ headers: { 'Content-Type': 'application/json' } }
	);
	if (!res.ok) {
		console.error(res.error);
		return [];
	}
	return res.json();
};

export const generateStoryMiddle = async (
	args: GenerateStoryMiddleArgs
): Promise<CustomGenerateResponse> => {
	const res = await client.gen.middle.$post({ json: { password: 'roodab', ...args } });
	if (!res.ok) {
		console.error(res.error);
		return [];
	}
	return res.json();
};

export const generateStoryBottom = async (
	args: GenerateStoryBottomArgs
): Promise<{ generated: string[] }> => {
	const res = await client.gen.bottom.$post({ json: { password: 'roodab', ...args } });
	if (!res.ok) {
		console.error(res.error);
		return [];
	}
	return res.json();
};

export const generateSummary = async (
	args: GenerateSummaryArgs
): Promise<CustomGenerateResponse> => {
	const res = await client.gen.summary.$post({ json: { password: 'roodab', ...args } });
	if (!res.ok) {
		console.error(res.error);
		return [];
	}
	return res.json();
};

export const createStory = async (): Promise<number> => {
	const res = await client.stories.new.$post(
		{ json: {} },
		{ headers: { Authorization: `Bearer ${supabase_jwt.jwt}` } }
	);

	if (!res.ok) {
		toastError(res.statusText);
		console.error(res.statusText);
		return;
	}

	if (res.error) {
		toastError(res.error);
		console.error(res.error);
		return;
	}

	return (await res.json()).data.storyId;
};

export const getStory = async (storyId: string): Promise<string> => {
	console.log('Attempting to get storyID: ', storyId);
	const res = await client.stories[':storyId'].$get(
		{ json: {}, param: { storyId } },
		{ headers: { Authorization: `Bearer ${supabase_jwt.jwt}` } }
	);

	if (!res.ok) {
		toastError(res.statusText);
		console.error(res.statusText);
		return;
	}

	if (res.error) {
		toastError(res.error);
		console.error(res.error);
		return;
	}

	return res.text();
};

export const updateStory = async (storyId: string, data: any): Promise<void> => {
	const res = await client.stories[':storyId'].$put(
		{ json: { newStoryJson: { firstPeakFromCloud: true, ...data } }, param: { storyId } },
		{ headers: { Authorization: `Bearer ${supabase_jwt.jwt}` } }
	);

	if (!res.ok) {
		toastError(res.statusText);
		console.error(res.statusText);
		return;
	}

	if (res.error) {
		toastError(res.error);
		console.error(res.error);
		return;
	}
};
