import { Link } from 'react-router-dom';
import '../pages/Home.scss'; // We're using the pre-written css from stolen project

export const PublicHeader = () => {
	return (
		<div className="marketing">
			<header role="banner" className="ui-section-header">
				<div className="ui-layout-container">
					<div className="ui-section-header__layout ui-layout-flex">
						{/* <!-- LOGO --> */}
						<Link to="/">
							{/* Have to specify the height in style instead of as attribute because this prevents CSS from overwriting it */}
							<img src="logo-text.svg" style={{ height: '50px' }} alt="logo" />
						</Link>
						{/* <!-- MENU --> */}
						<Link
							to="/login"
							role="link"
							aria-label="login"
							className="ui-component-button ui-component-button-small ui-component-button-primary"
						>
							Get Started
						</Link>
					</div>
				</div>
			</header>
		</div>
	);
};
