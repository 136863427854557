import { create, StateCreator } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import produce from 'immer';

import {
	Connection,
	Edge,
	EdgeChange,
	NodeChange,
	addEdge,
	OnNodesChange,
	OnEdgesChange,
	OnConnect,
	applyNodeChanges,
	applyEdgeChanges,
	XYPosition,
} from 'reactflow';

import { initialNodes, NodeData, Node, NodeType } from './nodes';
import { initialEdges } from './edges';
import { getCurrentStoryIdFromUrl, toastNotify } from '../utils';
import { generateStoryMiddle, generateStoryOutline, generateSummary, updateStory } from './api';
import { TopNodeData } from './Nodes/TopNode';
import { EventThemePair, parseEventThemeResponse } from './utils';
import { DeepPartial } from '../customTypes';
import { nanoid } from 'nanoid';
import { ReactElement } from 'react';
import { Json } from '../types/supabase';

export interface Choice {
	id: string;
	label: string;
	choices: ReactElement;
	onChoose: () => void;
}

export type RFState = {
	nodes: Node[];
	edges: Edge[];
	updateNodeData: (id: string, data: Partial<NodeData>) => void;
	onNodesChange: OnNodesChange;
	onEdgesChange: OnEdgesChange;
	onConnect: OnConnect;
	createOrphanNode: (idealX?: number, idealY?: number) => void;
	createNode: (nodeInfo: DeepPartial<Node>) => void;
	getNodeById: (id: string) => Node | undefined;
	getNodesByIds: (ids: string[]) => Node[];
	getChildren: (id: string) => string[];
	getSortedChildren: (id: string) => Node[];
	getThisChapterSummary: (id: string) => Promise<string | undefined>;
	getParent: (id: string) => string | undefined;
	getSummaryOfNode: (id: string) => Promise<string>;
	getSummary: (id: string, options?: GetSummaryOptions) => Promise<string>;
	resetNodes: () => void;
	getRootNode: () => Node | undefined;
	getOutline: (id: string) => Promise<EventThemePair[][] | undefined>;
	getMidNodeChoices: (id: string) => Promise<EventThemePair[][] | undefined>;
	createManyNodes: (nodeInfos: DeepPartial<Node>[]) => void;
	createManyEdges: (edgeInfos: Edge[]) => void;
	convertNodeType: (id: string, type: NodeType) => void;
	importJotte: (jotte: object) => void;
};

const LOCALSTORAGE_KEY = 'reactflow-storage';

const getLocalStorage = () => {
	const item = localStorage.getItem(LOCALSTORAGE_KEY);

	if (item) {
		return JSON.parse(item);
	}

	return undefined;
};

export interface GetSummaryOptions {
	recurseUp?: boolean;
	recurseDown?: boolean;
}

export interface SaveToCloudOptions<S, PersistedState = S> {
	/**
	 * Number of milliseconds to wait before saving the state to cloud.
	 * @default 1000
	 */
	debounceTime?: number;
}

type SaveToCloudType = <T>(storeInitializer: StateCreator<T, [], []>) => StateCreator<T, [], []>;

let timeout: NodeJS.Timeout | undefined;

const log: SaveToCloudType = config => (set, get, api) => {
	const debounceTime = 1000;

	console.log('config', config);
	return config(
		(args: any) => {
			if (timeout) {
				clearTimeout(timeout);
			}
			console.log('args', args);

			set(args);
			// Hacky workaround to prevent uploading the data the moment it is downloaded from the cloud, since "set" is called during input
			if (!args.firstPeakFromCloud) {

				// don't spam the server
				timeout = setTimeout(() => {
					// update story on cloud using api
					(async () => {
						console.log('get', JSON.parse(JSON.stringify(get())));
						updateStory(getCurrentStoryIdFromUrl(), get());
						toastNotify('Saved to cloud!');
					})();
				}, debounceTime);
			}

			return set;
		},
		get,
		api
	);
};
// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useStore = create<RFState>()(
	// devtools(
	// persist(
	log(
		(set, get) =>
			({
				nodes: [],
				edges: [],
				importJotte: (jotte: any) => {
					console.log('Imported Data', jotte);
					set({ ...jotte });
				},
				resetNodes: () => {
					get().importJotte({ nodes: initialNodes, edges: initialEdges });
				},
				updateNodeData: (id: string, data: Partial<NodeData>) => {
					set({
						nodes: get().nodes.map(node => {
							if (node.id === id) {
								return {
									...node,
									data: { ...node.data, ...data },
								};
							}

							return node;
						}),
					});
				},
				onNodesChange: (changes: NodeChange[]) => {
					// Filter out only the allowed types lol
					const filteredChanges = changes.filter(change => {
						const { type } = change;
						if (type === 'select' || type === 'position' || type === 'dimensions') {
							return true;
						} else if (type === 'remove') {
							const nodeId = change.id;
							const node = get().getNodeById(nodeId);
							if (node?.type === 'top') {
								toastNotify('Cannot remove top node');
								return false;
							} else {
								return true;
							}
						} else {
							const { item } = change;
							if (item.type !== 'top' && item.type !== 'mid' && item.type !== 'bottom') {
								toastNotify(`error, Tried to set invalid node type for ${item.id}`);
								return false;
							} else {
								return true;
							}
						}
					});

					set({
						// @ts-expect-error 2322
						nodes: applyNodeChanges(filteredChanges, get().nodes),
					});
				},
				getNodeById: (id: string) => {
					return get().nodes.find(node => node.id === id);
				},
				getNodesByIds: (ids: string[]) => {
					return get().nodes.filter(node => ids.includes(node.id));
				},
				createOrphanNode: (idealX: number = 200, idealY: number = 200) => {
					// Cascade node creations to avoid overlapping nodes
					const positions = get().nodes.map(node => node.position);
					const idealPosition: XYPosition = { x: idealX, y: idealY };
					const isPositionOccupied = (candidatePosition: XYPosition) =>
						positions.some(({ x, y }) => x === candidatePosition.x && y === candidatePosition.y);
					while (isPositionOccupied(idealPosition)) {
						idealPosition.x += 50;
						idealPosition.y += 50;
					}

					set(
						produce(state => {
							state.nodes.push({
								id: (state.nodes.length + 1).toString(),
								data: {
									label: `Node ${state.nodes.length + 1}`,
									theme: '',
									events: '',
									children: [],
									parent: '',
								},
								position: idealPosition,
								type: 'mid',
							});
						})
					);
				},
				createNode: (nodeInfo: DeepPartial<Node>) => {
					const { data } = nodeInfo;
					set(
						produce(state => {
							state.nodes.push({
								id: (state.nodes.length + 1).toString(),
								position: { x: 0, y: 0 },
								type: 'mid',
								...nodeInfo,
								data: {
									label: `Node ${state.nodes.length + 1}`,
									theme: '',
									events: '',
									...data,
								},
							});
						})
					);
				},
				createManyNodes: (nodeInfos: DeepPartial<Node>[]) => {
					set(
						produce(state => {
							nodeInfos.forEach(nodeInfo => {
								state.nodes.push({
									id: nanoid(),
									position: { x: 0, y: 0 },
									type: 'mid',
									...nodeInfo,
									data: {
										label: `Node ${state.nodes.length + 1}`,
										theme: '',
										events: '',
										...nodeInfo.data,
									},
								});
							});
						})
					);
				},
				createManyEdges: (edgeInfos: Edge[]) => {
					set(
						produce(state => {
							edgeInfos.forEach(edgeInfo => {
								state.edges.push({
									...edgeInfo,
								});
							});
						})
					);
				},
				onEdgesChange: (changes: EdgeChange[]) => {
					set({
						edges: applyEdgeChanges(changes, get().edges),
					});
				},
				getChildren: (id: string) => {
					const nodeChildrenIds = get()
						.edges.filter(edge => edge.source === id)
						.map(edge => edge.target);

					if (nodeChildrenIds.length === 0) {
						return [];
					}
					return nodeChildrenIds;
				},
				getSortedChildren: (id: string) => {
					const childrenIds = get().getChildren(id);
					if (childrenIds.length === 0) {
						return [];
					}
					const children = get().getNodesByIds(childrenIds);
					const sortedChildren = children.sort((a, b) => {
						return a.position.x - b.position.x;
					});
					return sortedChildren;
				},
				getParent: (id: string) => {
					return get().edges.find(edge => edge.target === id)?.source;
				},
				getRootNode: () => {
					const rootNode = get().nodes.find(node => node.type === 'top');
					if (!rootNode) {
						return undefined;
					}
					return rootNode;
				},
				getOutline: async (id: string) => {
					console.log('getOutline');
					const node = get().nodes.find(node => node.id === id);
					if (!node || !node.data) {
						console.log('no node or no data');
						return Promise.resolve(undefined);
					}
					const data: NodeData = node.data;
					const { theme, events } = data;
					const outline = await generateStoryOutline({ theme, events });
					const eventThemePairs = parseEventThemeResponse(outline);
					console.log('eventThemePairs', eventThemePairs);
					return eventThemePairs;
				},
				getMidNodeChoices: async (id: string) => {
					// Get this node's data
					const node = get().nodes.find(node => node.id === id);
					if (!node || !node.data) {
						return Promise.resolve(undefined);
					}
					const data: NodeData = node.data;
					const { theme: chapterTheme, events: chapterEvents } = data;

					// Get the root node's data
					const rootNode = get().getRootNode();
					if (!rootNode || !rootNode.data || !rootNode.data.theme) {
						return Promise.resolve(undefined);
					}
					const rootData: TopNodeData = rootNode.data;
					const { theme: globalTheme } = rootData;

					// get parent's data
					const parentId = get().getParent(id);
					if (!parentId) {
						return Promise.resolve(undefined);
					}
					const parent = get().getNodeById(parentId);
					if (!parent || !parent.data || !parent.data.theme || !parent.data.events) {
						return Promise.resolve(undefined);
					}
					const parentData: NodeData = parent.data;
					const { theme: parentTheme, events: parentEvents } = parentData;

					// chapterPreviousSummary: string; // Summary of previous events that happened in this chapter

					const chapterPreviousSummary = 'nothing';
					const outline = await generateStoryMiddle({
						globalTheme,
						chapterTheme,
						chapterEvents,
						parentEvents,
						parentTheme,
						chapterPreviousSummary,
					});
					const eventThemePairs = parseEventThemeResponse(outline);
					return eventThemePairs;
				},
				getThisChapterSummary: async (id: string) => {
					const node = get().nodes.find(node => node.id === id);
					if (node?.id && typeof node === 'object') {
						const parentId = get().getParent(id);
						if (!parentId) {
							return Promise.resolve('');
						}

						const allChildren = get().getSortedChildren(parentId);
						// Find all children before this node
						const nodeIndex = allChildren.indexOf(node);
						const childrenBefore = allChildren.slice(0, nodeIndex);

						// Summarize the children before.
						let summary = '';
						for (let i = 0; i < childrenBefore.length; i++) {
							const child = childrenBefore[i];

							if (!child?.data?.summary) {
								await get().getSummary(child.id, { recurseDown: true });
							} else {
								summary += child.data.summary;
							}
						}

						if (summary.split(' ').length > 100) {
							// send to AI for summarization if too long
							const res = await generateSummary({ text: summary });
							summary = res.generated[0].text;
						}

						return Promise.resolve(summary);
					}
					return Promise.resolve('');
				},
				convertNodeType: (id: string, type: NodeType) => {
					set(
						produce(state => {
							const node = state.nodes.find((node: Node) => node.id === id);
							if (node) {
								node.type = type;
							}

							state.edges = state.edges.filter((edge: Edge) => edge.source !== id);
						})
					);
				},
				getSummaryOfNode: async (id: string): Promise<string> => {
					const node = get().nodes.find(node => node.id === id);
					if (!node || !node.data) {
						return Promise.resolve('');
					}

					let prevSummary = '';

					// Get the summary of the parent
					const parentId = get().getParent(id);
					if (parentId) {
						const parent = get().getNodeById(parentId);
						if (parent?.data?.summary) {
							prevSummary += parent.data.summary;
						}
					}

					// Summarize by
					const childrenIds = get().getChildren(id);

					if (childrenIds?.length) {
						const children = get().getNodesByIds(childrenIds);

						if (children) {
							console.log('children', children);
							// sort by x position
							children.sort((a, b) => a.position.x - b.position.x);

							// Concatenate the summaries of each of the children
							const childrenSummary = children.reduce((acc, child) => {
								console.log('child data', child.data);
								if (child.data.summary) {
									return acc + ' ' + child.data.summary;
								} else {
									return acc;
								}
							}, '');
							console.log('childrenSummary', childrenSummary);

							prevSummary += childrenSummary;
						}
					}

					if (!prevSummary) {
						prevSummary = 'the story started';
					}

					// Send it to the server to summarize
					const response = await generateSummary({ text: prevSummary });
					console.log('response', response);

					if (response?.generated && response.generated.length > 0) {
						const summary = response.generated[0];

						// Update the node's summary
						set({
							nodes: get().nodes.map(node => {
								if (node.id === id) {
									return {
										...node,
										data: { ...node.data, summary: summary.text },
									};
								}
								return node;
							}),
						});
						return Promise.resolve(summary.text);
					}
					return Promise.resolve('');
				},
				getSummary: async (id: string) => {
					const node = get().nodes.find(node => node.id === id);
					if (!node) {
						return Promise.resolve('');
					}

					let prevSummary = '';

					const parentId = get().getParent(id);
					// Get the parent's summary, if present.
					// If it isn't present, create it, then get it.
					if (parentId) {
						const parent = get().getNodeById(parentId);
						if (parent?.data?.summary) {
							prevSummary += parent.data.summary;
						} else {
							console.error('Parent summary not found');
						}
					}

					// Summarize by
					const childrenIds = get().getChildren(id);

					if (childrenIds?.length) {
						const children = get().getNodesByIds(childrenIds);

						if (children) {
							console.log('children', children);
							// sort by x position
							children.sort((a, b) => a.position.x - b.position.x);

							// Concatenate the summaries of each of the children
							const childrenSummary = children.reduce((acc, child) => {
								console.log('child data', child.data);
								return acc + ' ' + child.data.summary;
							}, '');
							console.log('childrenSummary', childrenSummary);

							prevSummary += childrenSummary;
						}
					}

					if (!prevSummary) {
						prevSummary = 'the story started';
					}

					// Send it to the server to summarize
					const response = await generateSummary({ text: prevSummary });
					console.log('response ', id, response);

					if (response?.generated && response.generated.length > 0) {
						const summary = response.generated[0].text;

						// Update the node's summary
						set({
							nodes: get().nodes.map(node => {
								if (node.id === id) {
									return {
										...node,
										data: { ...node.data, summary: summary },
									};
								}
								return node;
							}),
						});
					}
				},

				onConnect: (connection: Connection) => {
					if (!connection.source) {
						toastNotify("Can't connect without a source");
						return;
					}

					if (!connection.target) {
						toastNotify("Can't connect without a target");
						return;
					}

					// You can't connect a node to itself
					if (connection.target === connection.source) {
						toastNotify("You can't connect a node to itself!");
						return;
					}

					// You can't connect to a node that already has a parent
					const hasParent = get().edges.find(edge => edge.target === connection.target);
					if (hasParent) {
						toastNotify("You can't connect to a node that already has a parent!");
						return;
					}

					set({
						edges: addEdge(connection, get().edges),
					});
				},
			} as RFState)
	)
	// 	{ name: LOCALSTORAGE_KEY }
	// )
	// )
);
