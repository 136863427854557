import { Dna } from 'react-loader-spinner';

export const Loader = () => {
	return (
		<div>
			<Dna
				visible={true}
				height="80"
				width="80"
				ariaLabel="dna-loading"
				wrapperStyle={{}}
				wrapperClass="dna-wrapper"
			/>
			<p>Crafting the best writing experience for you...</p>
		</div>
	);
};
