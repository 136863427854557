import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createStory } from '../Flow/api';
import { supabase } from '../supabaseClient';
import { Json } from '../types/supabase';

interface Story {
	created_at: string | null;
	description: string;
	id: string | null;
	owner_id: string;
	permissions: Json;
	title: string;
	updated_at: string | null;
}

interface StoryBlockProps {
	story: Story;
}

const StoryBlock = (props: StoryBlockProps) => {
	return (
		<div key={props.story.id} style={{ border: '1px solid black', margin: '2rem' }}>
			<h3>{props.story.title}</h3>
			<div>{props.story.description}</div>
			<Link to={`/app/story/${props.story.id}`}>Edit</Link>
		</div>
	);
};

export const AppDashboard = () => {
	const [stories, setStories] = useState<Story[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const user = await supabase.auth.getUser();

			if (user) {
				const { data, error } = await supabase
					.from('stories')
					.select('*')
					.eq('owner_id', user.data.user?.id)
					.order('updated_at', { ascending: false });

				if (error) {
					console.log(error);
					return;
				}

				setStories(data);
			}
		})();
	}, []);

	const onNewStoryClick = async () => {
		const newId = await createStory();
		if (newId) {
			navigate(`/app/story/${newId}`);
		}
	};

	return (
		<div>
			<h1>Dashboard</h1>

			<h2>Stories</h2>
			{stories.map(story => (
				<StoryBlock key={story.id} story={story} />
			))}

			<button onClick={onNewStoryClick}>Create new Story</button>
		</div>
	);
};
