import { nanoid } from "nanoid";
import { memo, useCallback } from "react";
import { Edge } from "reactflow";
import { DeepPartial } from "../customTypes";

import { shallow } from "zustand/shallow";
import { type RFState, useStore } from "./store";
import { EventThemePair } from "./utils";
import { Node, NodeType } from "./nodes";

interface ChooserProps {
  choices: EventThemePair[][];
  setChoices: (choices: EventThemePair[][]) => void;
  xPos: number;
  yPos: number;
  parentId: string;
  createdNodeType?: NodeType;
}

const selector = (state: RFState) => ({
  createManyNodes: state.createManyNodes,
  createManyEdges: state.createManyEdges,
});

export const Chooser = memo(
  ({
    choices: outlineChoices,
    xPos,
    yPos,
    parentId,
    setChoices: setOutlineChoices,
    createdNodeType = "mid",
  }: ChooserProps) => {
    const { createManyNodes, createManyEdges } = useStore(selector, shallow);

    const onChoose = useCallback(
      (choice: EventThemePair[]) => {
        const desiredCoords = { x: xPos - 500, y: yPos + 600 };
        const xDelta = 400;
        setOutlineChoices([]);
        const newNodes: DeepPartial<Node>[] = [];
        choice.forEach(({ theme, event }, ind) => {
          const position = {
            x: desiredCoords.x + xDelta * ind,
            y: desiredCoords.y,
          };
          newNodes.push({
            id: `mid-${nanoid()}`,
            data: {
              theme: theme,
              events: event,
            },
            type: createdNodeType,
            position: position,
          });
        });

        createManyNodes(newNodes);

        // Then, parent the new nodes to this one
        const newEdges: Edge[] = newNodes.map((node) => ({
          id: `edge-${nanoid()}`,
          source: parentId,
          target: node.id || "", // need || "" otherwise ts gets angry
        }));

        createManyEdges(newEdges);
      },
      [
        createManyNodes,
        createManyEdges,
        setOutlineChoices,
        parentId,
        createdNodeType,
        xPos,
        yPos,
      ]
    );

    if (outlineChoices.length === 0) {
      return null;
    }

    return (
      <div
        style={{
          padding: "1rem",
        }}
      >
        <div>
          <table className="choiceTable">
            <thead>
              <tr>
                <th scope="col">Step</th>
                <th scope="col">Event</th>
                <th scope="col">Theme</th>
              </tr>
            </thead>
            <tbody>
              {outlineChoices.map((choice, ind) => (
                <>
                  <tr key={`choice-${ind}`}>
                    <td
                      style={{
                        borderTop: "1px solid #9381ff",
                        // borderBottom: "1px solid #9381ff",
                      }}
                      colSpan={3}
                    >
                      Choice {ind + 1}
                    </td>
                  </tr>
                  {choice.map((pair, indPair) => (
                    <tr
                      key={`choice-${ind}-${indPair}`}
                      onClick={() => {
                        onChoose(choice);
                      }}
                    >
                      <td>{indPair + 1}.</td>
                      <td>{pair.event}</td>
                      <td>{pair.theme}</td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
