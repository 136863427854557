import { toast, ToastOptions } from 'react-toastify';
import { supabase } from './supabaseClient';
import { generateSlug } from 'random-word-slugs';

const DEFAULT_TOAST_CONFIG: ToastOptions<{}> = {
	position: 'top-right',
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

export const toastNotify = (text: string) => {
	toast(text, DEFAULT_TOAST_CONFIG);
};

export const toastSuccess = (text: string) => {
	toast.success(text, DEFAULT_TOAST_CONFIG);
};

export const toastWarning = (text: string) => {
	toast.warn(text, DEFAULT_TOAST_CONFIG);
};

export const toastError = (text: string) => {
	toast.error(text, DEFAULT_TOAST_CONFIG);
};

export const supabase_jwt = { jwt: '' };


export const getCurrentStoryIdFromUrl = () => window.location.pathname.split("/")[3];
