import { ReactNode, useEffect, useState } from 'react';

import { CountUpTimer } from 'react-countup-timer';
import { Grid } from 'react-loader-spinner';

interface LoadingProps {
	depArray: any[];
	children?: ReactNode;
}
export const Loading = (props: LoadingProps) => {
	const [loading, setLoading] = useState(Date.now() / 1000);

	// This seems sus as hell right now.
	// But it seems to work? Let's keep it.
	useEffect(() => {
		if (props.depArray.find(x => !!x)) {
			setLoading(Date.now() / 1000);
		} else {
			setLoading(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, props.depArray);

	if (!loading) {
		return <>{props.children}</>;
	}

	return (
		<div>
			<div
				style={{
					background: 'rgba(256, 256, 256, 0.9)',
					position: 'absolute',
					left: '0',
					top: '0',
					width: '100%',
					height: '100%',
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						<Grid
							height="60"
							width="60"
							color="#9381ff"
							ariaLabel="grid-loading"
							radius="12.5"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
							<span>Processing... (may take up to two minutes)</span>

							<CountUpTimer startTime={loading} />
						</div>
					</div>
				</div>
			</div>
			<div>{props.children}</div>
		</div>
	);
};
