import { useState } from 'react';
import { supabase } from '../supabaseClient';
import { toastError, toastNotify } from '../utils';

export const Login = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');

	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			setLoading(true);
			const { error } = await supabase.auth.signInWithOtp({ email });
			if (error) throw error;
			toastNotify('Check your email for the login link!');
		} catch (e: Error | any) {
			toastError(e.error_description || e.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="row flex-center flex">
			<div className="col-6 form-widget" aria-live="polite">
				<h1 className="header">Jotte Login</h1>
				<p className="description">Sign in via magic link with your email below</p>
				{loading ? (
					'Sending magic link...'
				) : (
					<form onSubmit={handleLogin}>
						<label htmlFor="email">Email</label>
						<input
							id="email"
							className="inputField"
							type="email"
							placeholder="Your email"
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<br />
						<button className="button block" aria-live="polite">
							Send magic link
						</button>
					</form>
				)}
			</div>
		</div>
	);
};
