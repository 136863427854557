import { Node as RFNode } from "reactflow";
export interface NodeData {
  flaggedForRefresh?: boolean;
  label: string;
  theme: string;
  events: string;
  summary?: string;
  imageUrl?: string;
}

export type NodeType = "top" | "mid" | "bottom";

export type Node = RFNode<NodeData, NodeType>;

export const initialNodes: Node[] = [
  {
    id: "1",
    type: "top",
    data: {
      label: "Overall Story",
      theme: "mystery, intrigue, fantasy, romance, hero's journey",
      events:
        "A poor girl named Janice unexpectedly discovers a map to a long-lost treasure across the sea. She sets out on a journey, meeting many new friends along the way. She eventually finds the treasure, but it is not what she expected.",
    },
    position: { x: 150, y: -450 },
  },
  // {
  //   id: "2",
  //   data: {
  //     label: "Chapter 1",
  //     theme: "",
  //     events: "",
  //   },
  //   position: { x: -100, y: 100 },
  //   type: "mid",
  // },
  // {
  //   id: "3",
  //   data: {
  //     label: "Chapter 2",
  //     theme: "",
  //     events: "",
  //   },
  //   position: { x: 400, y: 100 },
  //   type: "mid",
  // },
  // {
  //   id: "4",
  //   data: {
  //     label: "Paragraph 1",
  //     theme: "",
  //     events: "",
  //   },
  //   position: { x: -380, y: 700 },
  //   type: "mid",
  // },
  // {
  //   id: "5",
  //   data: {
  //     label: "Paragraph 2",
  //     theme: "",
  //     events: "",
  //   },
  //   position: { x: 100, y: 700 },
  //   type: "mid",
  // },
];
