import { RFState, useStore } from "./store";

import "./InteractionPanel.css";

import { BsNewspaper } from "react-icons/bs";
import { GrPowerReset } from "react-icons/gr";
import { AiOutlinePlusCircle } from "react-icons/ai";

const selector = (state: RFState) => ({
  createOrphanNode: state.createOrphanNode,
  resetNodes: state.resetNodes,
});

export const InteractionPanel = () => {
  const { createOrphanNode, resetNodes } = useStore(selector);

  return (
    <div className="interactionPanel">
      <button
        className="button-panel"
        onClick={() => (window.location.hash = "instructions")}
      >
        <a
          style={{ textDecorationLine: "none", color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://forms.gle/nPXRixJ8SmEb7WAN6"
        >
          🎉 Join our Waitlist
        </a>
      </button>
      <br />
      <button
        className="button-panel"
        onClick={() => (window.location.hash = "instructions")}
      >
        <BsNewspaper /> Show Instructions
      </button>
      <br />
      <hr />
      <button
        className="button-panel"
        onClick={() => {
          createOrphanNode();
        }}
      >
        <AiOutlinePlusCircle /> Create New Node
      </button>
      <br />
      <button
        className="button-panel"
        onClick={() => {
          resetNodes();
        }}
      >
        <GrPowerReset /> Reset Nodes
      </button>
    </div>
  );
};
