import { createBrowserRouter, Outlet } from 'react-router-dom';
import { NoAuth } from './components/NoAuth';
import { RequireAuth } from './components/RequreAuth';
import { Account } from './pages/Account';
import { App } from './pages/App';
import { AppDashboard } from './pages/AppDashboard';
import { Home } from './pages/Home';
import { Login } from './pages/Login';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/login',
		element: (
			<NoAuth>
				<Login />
			</NoAuth>
		),
	},
	{
		path: '/account',
		element: (
			<RequireAuth>
				<Account />
			</RequireAuth>
		),
	},
	{
		path: '/app',
		element: (
			<RequireAuth>
				<Outlet />
			</RequireAuth>
		),
		children: [
			{
				path: '',
				element: <AppDashboard />,
			},
			{
				path: 'story/:storyId',
				element: <App />,
			},
		],
	},
]);
