import { Session } from '@supabase/supabase-js';
import { StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { AuthContext } from './pages/AuthContext';
import { router } from './Routes';
import { supabase } from './supabaseClient';

import './index.css';
import { ToastContainer } from 'react-toastify';
import { supabase_jwt } from './utils';

const root = createRoot(document.getElementById('root') as HTMLElement);

const RootComponent = () => {
	const [session, setSession] = useState<Session | null>(null);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			if (session?.access_token) {
				supabase_jwt.jwt = session.access_token;
			}
		});

		supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
			if (session?.access_token) {
				supabase_jwt.jwt = session.access_token;
			}
		});
	}, []);

	return (
		<StrictMode>
			<AuthContext.Provider value={session}>
				<RouterProvider router={router} />
				<ToastContainer />
			</AuthContext.Provider>
		</StrictMode>
	);
};

root.render(<RootComponent />);
