import { useCallback, useState } from "react";
import { useStore } from "../store";


export const useSummarize = (id: string) => {
  const getSummary = useStore((s) => s.getSummary);
  const [loading, setLoading] = useState(false);

  const summarize = useCallback(async () => {
    setLoading(true);
    await getSummary(id);
    setLoading(false);
  }, [id, getSummary]);

  return { summarize, loading };
};
