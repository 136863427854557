import ReactFlow, { MiniMap, Controls, Background, Panel } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { RFState, useStore } from './store';
import { MidNode } from './Nodes/MidNode';

// this is important! You need to import the styles from the lib to make it work
import 'reactflow/dist/style.css';

import './Flow.css';
import { InteractionPanel } from './InteractionPanel';
import { TopNode } from './Nodes/TopNode';
import { BottomNode } from './Nodes/BottomNode';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { getStory } from './api';
import { Loader } from '../components/Loader';

const nodeTypes = {
	// root:
	top: TopNode,
	mid: MidNode,
	bottom: BottomNode,
};

const selector = (state: RFState) => ({
	nodes: state.nodes,
	edges: state.edges,
	onNodesChange: state.onNodesChange,
	onEdgesChange: state.onEdgesChange,
	onConnect: state.onConnect,
	importJotte: state.importJotte,
});

interface FlowProps {
	storyId: string;
}

export function Flow(props: FlowProps) {
	const { nodes, edges, onNodesChange, onEdgesChange, onConnect, importJotte } = useStore(
		selector,
		shallow
	);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const story = await getStory(props.storyId);
			if (story) {
				importJotte(JSON.parse(story).data);
				setIsLoading(false);
			}
		})();
	}, [importJotte, props.storyId]);

	return (
		<div className="Flow">
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						height: '100%',
						alignItems: 'center',
					}}
				>
					<Loader />
				</div>
			) : (
				<ReactFlow
					nodes={nodes}
					onNodesChange={onNodesChange}
					edges={edges}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					fitView
					nodeTypes={nodeTypes}
					deleteKeyCode={['Backspace', 'Delete']}
					proOptions={{ hideAttribution: true }}
				>
					<Panel position="top-right">
						<InteractionPanel />
					</Panel>

					<BrowserView>
						<MiniMap position="bottom-right" zoomable pannable />
					</BrowserView>
					<Controls />
					<Background />
				</ReactFlow>
			)}
		</div>
	);
}
