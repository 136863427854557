import { memo, useCallback, useState } from "react";
import { Handle, Position, NodeProps, Node, NodeToolbar } from "reactflow";
import { shallow } from "zustand/shallow";

import { RFState, useStore } from "../store";
import { useSummarize } from "../hooks/useSummarize";
import TextareaAutosize from "react-textarea-autosize";

import "@reactflow/node-resizer/dist/style.css";
import { EventThemePair } from "../utils";
import { toastNotify } from "../../utils";
import { Chooser } from "../Chooser";
import { Loading } from "../Loading";

export interface TopNodeData {
  label: string;
  theme: string;
  events: string;
  summary?: string;
  imageUrl?: string;
}

export type TopNodeType = Node<TopNodeData>;

const selector = (state: RFState) => ({
  updateNodeData: state.updateNodeData,
  getOutline: state.getOutline,
});

export const TopNode = memo(({ data, selected, id, xPos, yPos }: NodeProps) => {
  const { updateNodeData, getOutline } = useStore(selector, shallow);

  const [outlineLoading, setOutlineLoading] = useState(false);
  const [outlineChoices, setOutlineChoices] = useState<EventThemePair[][]>([]);

  const { summarize, loading: summarizeLoading } = useSummarize(id);

  const onDataChange = useCallback(
    (delta: Partial<TopNodeData>) => {
      // console.log(evt.target.value);
      updateNodeData(id, delta);
    },
    [id, updateNodeData]
  );

  const onGenerateOutline = useCallback(async () => {
    setOutlineLoading(true);
    const outline = await getOutline(id);
    console.log(outline);
    if (outline) {
      toastNotify(`${outline.length} outline choices generated.`);
      setOutlineChoices(outline);
    }
    setOutlineLoading(false);
  }, [getOutline, id]);

  return (
    <div
      style={{ display: "flex", flexDirection: "row" }}
      className={selected ? "selected" : ""}
    >
      {/* <NodeResizer isVisible={selected} /> */}
      <NodeToolbar isVisible={selected} position={Position.Bottom}>
        <button className="btn-green" onClick={summarize}>
          Summarize
        </button>
        <button className="btn-green" onClick={onGenerateOutline}>
          Generate Outline
        </button>
      </NodeToolbar>
      <Handle type="source" position={Position.Bottom} id="out" />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Loading depArray={[summarizeLoading, outlineLoading]}>
          <>
            <div>
              <div>
                Label:{" "}
                <strong>
                  <input
                    type="text"
                    name={`label-${id}`}
                    value={data.label}
                    onChange={(e) => onDataChange({ label: e.target.value })}
                  />
                </strong>
              </div>

              <div>
                <label htmlFor={`theme-${id}`}>Theme:</label>
                <br />
                <TextareaAutosize
                  value={data.theme}
                  id={`theme-${id}`}
                  name={`theme-${id}`}
                  onChange={(e) => onDataChange({ theme: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`events-${id}`}>Events:</label>
                <br />
                <TextareaAutosize
                  value={data.events}
                  id={`events-${id}`}
                  name={`events-${id}`}
                  onChange={(e) => onDataChange({ events: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`summary-${id}`}>
                  Summary: (Edit this as little as possible unless there is
                  error)
                </label>
                <br />
                <TextareaAutosize
                  value={data.summary}
                  id={`summary-${id}`}
                  name={`summary-${id}`}
                  onChange={(e) => onDataChange({ summary: e.target.value })}
                  className="nodrag"
                />
              </div>
            </div>
          </>
        </Loading>
      </div>
      <Chooser
        choices={outlineChoices}
        xPos={xPos}
        yPos={yPos}
        parentId={id}
        setChoices={setOutlineChoices}
      />
    </div>
  );
});
