import { useState, useEffect } from 'react';
import { Loader } from '../components/Loader';
import { supabase } from '../supabaseClient';
import { toastError } from '../utils';
import { useAuth } from '../hooks/useAuth';

export const Account = () => {
	const session = useAuth();
	const [loading, setLoading] = useState(true);
	const [username, setUsername] = useState('');
	const [bio, setBio] = useState('');
	const [avatar_url, setAvatarUrl] = useState('');

	useEffect(() => {
		getProfile();
	}, [session]);

	if (!session) {
		return <Loader />;
	}

	const getProfile = async () => {
		try {
			setLoading(true);
			const { user } = session;

			let { data, error, status } = await supabase
				.from('profiles')
				.select(`name, bio, avatar_url`)
				.eq('id', user.id)
				.single();

			if (error && status !== 406) {
				throw error;
			}

			if (data) {
				setUsername(data.name);
				setBio(data.bio);
				setAvatarUrl(data.avatar_url);
			}
		} catch (error: Error | any) {
			alert(error.message);
		} finally {
			setLoading(false);
		}
	};

	const updateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			setLoading(true);
			const user = session?.user;

			const updates = {
				id: user.id,
				username,
				bio,
				avatar_url,
				updated_at: new Date(),
			};

			let { error } = await supabase.from('profiles').upsert(updates);

			if (error) {
				throw error;
			}
		} catch (error: Error | any) {
			toastError(error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div aria-live="polite">
			{loading ? (
				'Saving ...'
			) : (
				<form onSubmit={updateProfile} className="form-widget">
					<div>Email: {session?.user.email}</div>
					<div>
						<label htmlFor="username">Name</label>
						<input
							id="username"
							type="text"
							value={username}
							onChange={e => setUsername(e.target.value)}
						/>
					</div>
					<div>
						<label htmlFor="bio">Bio</label>
						<input id="bio" type="url" value={bio} onChange={e => setBio(e.target.value)} />
					</div>
					<div>
						<button className="button primary block" disabled={loading}>
							Update profile
						</button>
					</div>
				</form>
			)}
			<button type="button" className="button block" onClick={() => supabase.auth.signOut()}>
				Sign Out
			</button>
		</div>
	);
};
