import '../pages/Home.scss'; // We're using the pre-written css from stolen project

export const PublicFooter = () => {
	return (
		<div className="marketing">
			<footer role="contentinfo" className="ui-section-footer">
				<div className="ui-layout-container">
					<div className="ui-section-footer__layout ui-layout-flex">
						{/* <!-- COPYRIGHT --> */}
						<p className="ui-section-footer--copyright ui-text-note">
							<small>&copy; 0000 Uisual</small>
						</p>
						{/* <!-- MENU -->           */}
						<nav
							role="navigation"
							className="ui-section-footer--nav ui-section-footer--menu ui-layout-flex"
						>
							<a href="#" role="link" aria-label="#" className="ui-text-note">
								<small>Features</small>
							</a>
							<a href="#" role="link" aria-label="#" className="ui-text-note">
								<small>Help</small>
							</a>
							<a href="#" role="link" aria-label="#" className="ui-text-note">
								<small>Terms</small>
							</a>
						</nav>
						{/* <!-- SOCIAL --> */}
						<nav
							role="navigation"
							className="ui-section-footer--nav ui-section-footer--social ui-layout-flex"
						>
							<a href="#" role="link" aria-label="#">
								<svg
									viewBox="0 0 24 24"
									height="16"
									width="16"
									fill="none"
									stroke="#AEAEAE"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									role="img"
									aria-label="#"
								>
									<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
								</svg>
							</a>
							<a href="#" role="link" aria-label="#">
								<svg
									viewBox="0 0 24 24"
									height="16"
									width="16"
									fill="none"
									stroke="#AEAEAE"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									role="img"
									aria-label="#"
								>
									<rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
									<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zM17.5 6.5h.01" />
								</svg>
							</a>
							<a href="#" role="link" aria-label="#">
								<svg
									viewBox="0 0 24 24"
									height="16"
									width="16"
									fill="none"
									stroke="#AEAEAE"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									role="img"
									aria-label="#"
								>
									<circle cx="12" cy="12" r="10" />
									<path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32" />
								</svg>
							</a>
						</nav>
					</div>
				</div>
			</footer>
		</div>
	);
};
