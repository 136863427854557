import { memo, useCallback, useState } from "react";
import { Handle, Position, NodeProps, NodeToolbar } from "reactflow";
import { CountUpTimer } from "react-countup-timer";
import { shallow } from "zustand/shallow";
import TextareaAutosize from "react-textarea-autosize";

import { RFState, useStore } from "../store";
import { useSummarize } from "../hooks/useSummarize";
import { EventThemePair } from "../utils";
import { toastNotify } from "../../utils";
import { NodeData } from "../nodes";
import { Chooser } from "../Chooser";

import "@reactflow/node-resizer/dist/style.css";
import { Loading } from "../Loading";
const selector = (state: RFState) => ({
  updateNodeData: state.updateNodeData,
  getNodeById: state.getNodeById,
  getNodesByIds: state.getNodesByIds,
  getChildren: state.getChildren,
  getSummary: state.getSummary,
  getMidNodeChoices: state.getMidNodeChoices,
  convertNodeType: state.convertNodeType,
});

export const MidNode = memo(
  ({ data, selected, id, xPos, yPos, type }: NodeProps<NodeData>) => {
    const { updateNodeData, getMidNodeChoices, convertNodeType } = useStore(
      selector,
      shallow
    );

    const { summarize, loading: summarizeLoading } = useSummarize(id);

    const [midLoading, setMidLoading] = useState(false);
    const [midChoices, setMidChoices] = useState<EventThemePair[][]>([]);

    const [botLoading, setBotLoading] = useState(false);
    const [botChoices, setBotChoices] = useState<EventThemePair[][]>([]);

    const onDataChange = useCallback(
      (delta: Partial<NodeData>) => {
        // console.log(evt.target.value);
        updateNodeData(id, delta);
      },
      [id, updateNodeData]
    );

    const onGenerateMidNodes = useCallback(async () => {
      setMidLoading(true);
      const choices = await getMidNodeChoices(id);
      console.log(choices);
      if (choices) {
        toastNotify(`${choices.length} midnode choices generated.`);
        setMidChoices(choices);
      }
      setMidLoading(false);
    }, [id, getMidNodeChoices]);

    const onGenerateBotNodes = useCallback(async () => {
      setBotLoading(true);
      const choices = await getMidNodeChoices(id);
      console.log(choices);
      if (choices) {
        toastNotify(`${choices.length} bot choices generated.`);
        setBotChoices(choices);
      }
      setBotLoading(false);
    }, [id, getMidNodeChoices]);

    const convertToBottomNode = useCallback(() => {
      convertNodeType(id, "bottom");
    }, [convertNodeType, id]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        className={selected ? "selected" : ""}
      >
        <Handle type="target" position={Position.Top} />
        {/* <NodeResizer isVisible={selected}  /> */}
        <NodeToolbar isVisible={selected} position={Position.Bottom}>
          <button className="btn-green" onClick={summarize}>
            Summarize
          </button>
          <button className="btn-green" onClick={onGenerateMidNodes}>
            Generate Outline
          </button>
          <button className="btn-green" onClick={onGenerateBotNodes}>
            Generate Text Nodes
          </button>
          <button className="btn-green" onClick={convertToBottomNode}>
            Convert to Text Node
          </button>
        </NodeToolbar>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Loading depArray={[summarizeLoading, midLoading, botLoading]}>
            <div>
              <div>
                Label:{" "}
                <strong>
                  <input
                    type="text"
                    name={`label-${id}`}
                    value={data.label}
                    onChange={(e) => onDataChange({ label: e.target.value })}
                  />
                </strong>
              </div>

              <div>
                <label htmlFor={`theme-${id}`}>Theme:</label>
                <br />
                <TextareaAutosize
                  value={data.theme}
                  id={`theme-${id}`}
                  name={`theme-${id}`}
                  onChange={(e) => onDataChange({ theme: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`events-${id}`}>Events:</label>
                <br />
                <TextareaAutosize
                  value={data.events}
                  id={`events-${id}`}
                  name={`events-${id}`}
                  onChange={(e) => onDataChange({ events: e.target.value })}
                  className="nodrag"
                />
              </div>
              <div>
                <label htmlFor={`summary-${id}`}>
                  Summary: (Edit this as little as possible unless there is
                  error)
                </label>
                <br />
                <TextareaAutosize
                  value={data.summary}
                  id={`summary-${id}`}
                  name={`summary-${id}`}
                  onChange={(e) => onDataChange({ summary: e.target.value })}
                  className="nodrag"
                />
              </div>
            </div>
          </Loading>

          <Handle type="source" position={Position.Bottom} id="out" />
        </div>
        <Chooser
          choices={midChoices}
          xPos={xPos}
          yPos={yPos}
          parentId={id}
          setChoices={setMidChoices}
        />
        <Chooser
          choices={botChoices}
          xPos={xPos}
          yPos={yPos}
          parentId={id}
          setChoices={setBotChoices}
          createdNodeType="bottom"
        />
      </div>
    );
  }
);
